<script>
export default {
    name: 'DefaultMenu',
    methods: {
      async doLogout() {
        await this.$store.dispatch('auth/logout');
        this.$router.push("/login");
      }
    }
}
</script>

<template>
    <side-bar>

      <template slot="links">
        <!-- -->
        <sidebar-item :link="{name: $t('menu.overview'), icon: 'far fa-th-large', path: '/start'}" />
        <sidebar-item :link="{name: $t('menu.overview'), icon: 'far fa-th-large', path: '/dashboard'}" v-if="false" />

        <!-- -->
        <sidebar-item :link="{name: $t('menu.my_tasks'), icon: 'far fa-tasks', path: '/tasks/my-tasks'}" v-if="true" />
        <sidebar-item :link="{name: $t('menu.my_travels'), icon: 'far fa-cars', path: '/travels/my-travels'}" v-if="true" />
        <sidebar-item :link="{name: $t('menu.my_hours'), icon: 'far fa-hourglass', path: '/reports/my-hours'}" v-if="true" />
        <!-- -->
        <sidebar-item :link="{name: $t('menu.cases'), icon: 'far fa-clipboard-list-check'}" v-if="true">
          <sidebar-item :link="{ name: $t('menu.all_cases'), path: '/projects/index' }" />
          <sidebar-item :link="{ name: $t('menu.create_case'), path: '/projects/index?create=case' }" />
          <sidebar-item :link="{ name: $t('menu.my_cases_overview'), path: '/projects/my-overview' }" />
          <sidebar-item :link="{ name: $t('menu.my_cases_invoices'), path: '/projects/my-invoices' }" />
          <sidebar-item :link="{name: $t('menu.reopen_day'), icon: 'far fa-tasks', path: '/projects/reopen'}" v-if="$aclCan($ACL.TIMEREG_MGMT)" />
        </sidebar-item>


        <!-- -->
        <sidebar-item :link="{name: $t('menu.contracts'), icon: 'far fa-file-signature'}">
          <sidebar-item :link="{ name: $t('menu.contract_index'), path: '/contracts/index' }" />
          <sidebar-item :link="{ name: $t('menu.contract_terminations'), path: '/contracts/terminations' }" />
          <sidebar-item :link="{ name: $t('menu.currencies'), path: '/contracts/currencies' }" :hide-mobile="true" v-if="$aclCan($ACL.CONTRACT_MGMT)" />
          <sidebar-item :link="{ name: $t('menu.netto_price_index'), path: '/contracts/netprices' }" :hide-mobile="true" v-if="$aclCan($ACL.CONTRACT_MGMT)" />
          <sidebar-item :link="{ name: $t('menu.asset_categories'), path: '/contracts/asset-groups' }" :hide-mobile="true" />
          <sidebar-item :link="{ name: $t('menu.contract_products'), path: '/contracts/products' }" :hide-mobile="true" />
          <sidebar-item :link="{ name: $t('menu.settings'), path: '/contracts/settings' }" :hide-mobile="true" v-if="false" />
        </sidebar-item>

        <sidebar-item :link="{name: $t('menu.subscriptions'), icon: 'far fa-clock'}" v-if="$aclCan($ACL.CONTRACT_MGMT)">
          <sidebar-item :link="{ name: $t('menu.subscriptions_index'), path: '/subscriptions/index' }" :hide-mobile="true" v-if="$aclCan($ACL.CONTRACT_MGMT)" />
          <sidebar-item :link="{ name: $t('menu.mass_edit_subscriptions'), path: '/subscriptions/mass-edit' }" :hide-mobile="true" v-if="$aclCan($ACL.CONTRACT_MGMT)" />
          <sidebar-item :link="{ name: $t('menu.subscriptions_products'), path: '/subscriptions/products' }" :hide-mobile="true" v-if="$aclCan($ACL.CONTRACT_MGMT)" />
        </sidebar-item>

        <sidebar-item :link="{name: $t('menu.invoicing'), icon: 'far fa-file-invoice-dollar'}" :hide-mobile="true" v-if="$aclCan($ACL.INVOICE_MGMT)">
          <sidebar-item :link="{ name: $t('menu.invoicing_contracts'), path: '/invoicing/contracts' }" />
          <sidebar-item :link="{ name: $t('menu.invoicing_contract_balances'), path: '/invoicing/contract-balances' }" />
          <sidebar-item :link="{ name: $t('menu.invoicing_subscriptions'), path: '/invoicing/subscriptions' }" />
          <sidebar-item :link="{ name: $t('menu.invoicing_charges'), path: '/invoicing/charges' }" />
          <sidebar-item :link="{ name: $t('menu.invoicing_time'), path: '/invoicing/projects' }" />
          <sidebar-item :link="{ name: $t('menu.invoicing_outbox'), path: '/invoicing/outbox' }" />
          <sidebar-item :link="{ name: $t('menu.invoicing_templates'), path: '/invoicing/templates' }" />
          <sidebar-item :link="{ name: $t('menu.charge_templates'), path: '/invoicing/charge-templates' }" />
        </sidebar-item>

        <!-- -->
        <sidebar-item :link="{name: $t('menu.customers'), icon: 'far fa-building'}">
          <sidebar-item :link="{ name: $t('menu.all_customers'), path: '/customers/index' }" />
          <sidebar-item :link="{ name: $t('menu.new_customer'), path: '/customers/add' }" :hide-mobile="true" v-if="false" />
          <sidebar-item v-if="false" :link="{ name: $t('menu.labels'), path: '/labels' }" :hide-mobile="true" />
          <sidebar-item :link="{ name: $t('menu.settings'), path: '/customers/settings' }" :hide-mobile="true" v-if="$aclCan($ACL.CONTRACT_MGMT)" />
          <sidebar-item :link="{ name: $t('menu.import_customers'), path: '/customers/import' }" :hide-mobile="true"  v-if="$aclCan($ACL.CONTRACT_MGMT)" />
        </sidebar-item>

        <sidebar-item :link="{name: $t('menu.team'), icon: 'far fa-users', path: '/users/index'}" :hide-mobile="true" >
          <sidebar-item :link="{ name: $t('menu.users'), path: '/users/index' }" :hide-mobile="true" />
          <sidebar-item :link="{ name: $t('menu.departments'), path: '/departments/index' }" :hide-mobile="true" />
        </sidebar-item>

        <sidebar-item :link="{name: $t('menu.bookkeepers'), icon: 'far fa-book'}" :hide-mobile="true" v-if="$aclCan($ACL.INVOICE_MGMT)" >
            <sidebar-item :link="{name: $t('menu.travel_viewer'), icon: 'far fa-tasks', path: '/travels/index'}" v-if="$aclCan($ACL.INVOICE_MGMT)" />
            <sidebar-item :link="{name: $t('menu.travel_salary'), icon: 'far fa-tasks', path: '/travels/salary'}" v-if="$aclCan($ACL.INVOICE_MGMT)" />
            <sidebar-item :link="{name: $t('menu.travel_salary_reports'), icon: 'far fa-tasks', path: '/travels/salary-reports'}" v-if="$aclCan($ACL.INVOICE_MGMT)" />
        </sidebar-item>

        <!-- -->
        <sidebar-item :link="{name: $t('menu.reports'), icon: 'far fa-chart-pie'}" :hide-mobile="true" v-if="$aclCan($ACL.STATS_VIEW)" >
          <sidebar-item :link="{name: $t('menu.all_tasks'), icon: 'far fa-tasks', path: '/tasks/index'}" v-if="$aclCan($ACL.TIMEREG_MGMT)" />
          <sidebar-item :link="{name: $t('menu.all_cases_overview'), icon: 'far fa-tasks', path: '/projects/full-overview'}" v-if="$aclCan($ACL.TIMEREG_MGMT)" />
          <sidebar-item :link="{name: $t('menu.projects_invoicing_report'), icon: 'far fa-tasks', path: '/projects/invoicing-report'}" v-if="$aclCan($ACL.TIMEREG_MGMT)" />
          <sidebar-item :link="{ name: $t('menu.hour_report'), path: '/reports/activities' }" />
          <sidebar-item :link="{ name: $t('menu.absence'), path: '/reports/absence' }" v-if="$aclCan($ACL.PERSONAL_REPORT_MGMT)" />
          <sidebar-item :link="{ name: $t('menu.cases'), path: '/reports/projects' }" v-if="$aclCan($ACL.STATS_VIEW)" />
          <sidebar-item :link="{ name: $t('menu.cases_performance'), path: '/reports/projects-performance' }" v-if="$aclCan($ACL.STATS_VIEW)" />
          <sidebar-item :link="{ name: $t('menu.open_days'), path: '/reports/open-days' }" v-if="$aclCan($ACL.STATS_VIEW)" />
          <sidebar-item :link="{ name: $t('menu.contract_reports'), path: '/reports/contracts' }" :hide-mobile="true" v-if="$aclCan($ACL.STATS_VIEW)"  />
          <sidebar-item :link="{ name: $t('menu.personal_multiview'), path: '/reports/personal-multiview' }" :hide-mobile="true" v-if="$aclCan($ACL.PERSONAL_REPORT_MGMT)"  />
        </sidebar-item>

        <!-- -->
        <sidebar-item :link="{name: $t('menu.settings'), icon: 'far fa-cogs', path: '/settings'}" v-if="$aclCan($ACL.TIMEREG_MGMT)">
          <sidebar-item :link="{ name: $t('menu.activities'), path: '/settings/activities' }" />
          <sidebar-item :link="{ name: $t('menu.milestones'), path: '/settings/milestones' }" />
          <sidebar-item :link="{ name: $t('menu.role_prices'), path: '/settings/role-prices' }" />
          <sidebar-item :link="{ name: $t('menu.travel_prices'), path: '/travel-prices' }" />
        </sidebar-item>

        <sidebar-item :link="{name: $t('menu.my_account'), icon: 'far fa-user', path: '/profile'}">
        </sidebar-item>

        <sidebar-item :link="{name: $t('menu.help'), icon: 'far fa-question-circle', path: '/help'}">
        </sidebar-item>

        <a class="nav-link" href="#" @click.prevent="doLogout">
          <i class="far fa-sign-out"></i>
          <span class="nav-link-text">{{$t('menu.logout')}}</span>
        </a>

      </template>
    </side-bar>
</template>
